import { ALL_ROLES, CPO_ROLES, SERVICE_ROLES, SUPPORT_ROLE } from '../../../../security/roles'
import {
  Apps,
  BatteryChargingFull,
  CalendarToday,
  Construction,
  CreditCard,
  Info,
  InsertDriveFile,
  People,
  Person,
  ShowChart,
  Speed,
  Tune,
  VerifiedUser,
  ViewList,
} from '@mui/icons-material'

export const sidebarElements = {
  stations: {
    title: 'Ladestationen',
    path: '/stations',
    icon: Apps,
  },
  global: [
    {
      title: 'Nutzerverwaltung',
      path: '/users',
      icon: People,
      roles: SUPPORT_ROLE,
    },
    {
      title: 'Datenexport',
      path: '/export',
      icon: ViewList,
      roles: CPO_ROLES,
    },
    {
      title: 'Administration',
      path: '/administration',
      icon: Construction,
      roles: SUPPORT_ROLE,
    },
  ],
  general: [
    {
      title: 'Dashboard',
      path: '/client/:clientId/dashboard',
      icon: Speed,
      roles: ALL_ROLES,
    },
    {
      title: 'Meldungen',
      path: '/client/:clientId/messages',
      icon: Info,
      roles: ALL_ROLES,
    },
    {
      section: 'general',
      title: 'Konfiguration',
      path: '/client/:clientId/settings',
      icon: Tune,
    },
    {
      section: 'general',
      title: 'Freigabe',
      path: '/client/:clientId/authorization',
      icon: CreditCard,
    },
    {
      title: 'Vorgänge',
      path: '/client/:clientId/processes',
      icon: CalendarToday,
      roles: SERVICE_ROLES,
    },
    {
      title: 'Stammdaten',
      path: '/client/:clientId/baseData',
      icon: Person,
      roles: SERVICE_ROLES,
    },
    {
      title: 'Energiemanagement',
      path: '/client/:clientId/energy',
      icon: BatteryChargingFull,
      roles: SERVICE_ROLES,
    },
    {
      title: 'Dokumente',
      path: '/client/:clientId/documents',
      icon: InsertDriveFile,
      roles: SERVICE_ROLES,
    },
    {
      title: 'Auswertung',
      path: '/client/:clientId/evaluation',
      icon: ShowChart,
    },
    {
      title: 'Nutzerverwaltung',
      path: '/client/:clientId/users',
      icon: People,
      roles: SUPPORT_ROLE,
    },
    {
      title: 'Datenexport',
      path: '/client/:clientId/export',
      icon: ViewList,
      roles: CPO_ROLES,
    },
    {
      title: 'Zugriffsberechtigung',
      path: '/client/:clientId/user-management',
      icon: VerifiedUser,
      roles: CPO_ROLES,
    },
    {
      title: 'Logs',
      path: '/client/:clientId/logmessages',
      icon: Info,
      roles: SUPPORT_ROLE,
    },
    {
      title: 'Administration',
      path: '/administration',
      icon: Construction,
      roles: SUPPORT_ROLE,
    },
  ],
}